import PropTypes from 'prop-types';
import styled from 'styled-components';
import { Field, change, clearFields } from 'redux-form';
import { connect } from 'react-redux';
import { Button, T, theme, withWindowSize } from 'klara-ui/old';
import DropzoneField from 'redux/forms/elements/DropzoneField';
import FormErrors from 'redux/forms/elements/Errors';
import { reduxFormWithErrors } from 'redux/helpers';
import formatDate from 'util/formatDate';
import filterOutInvalidFile from 'util/filterOutInvalidFile';
import FilePreviewProvider from 'hooks/FilePreviewProvider';

const DropzonesMobileContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 20px;
  align-items: center;
  margin-top: 16px;

  > :nth-child(1) {
    margin-bottom: 10px;
  }

  > :nth-child(2) {
    margin-top: 10px;
  }
`;

const DropzonesDesktopContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 20px;
  margin-top: 16px;
  > :nth-child(1) {
    margin-right: 10px;
  }

  > :nth-child(2) {
    margin-left: 10px;
  }
`;

const DateContainer = styled((props) => <T {...props} />)`
  color: ${theme.colors.grey60};
  display: flex;
  align-items: center;
`;

const StyledButton = styled(Button)`
  margin-left: auto;
`;

const LowerContainer = styled.div`
  display: flex;
  align-items: center;
`;

export const FORM_NAME = 'patient/documentUploadForm';

const validate = (values) => {
  const errors = {};
  const files = values.files && values.files.filter((file) => !!file);

  if (!files || files.length === 0) {
    errors.files = 'not_present';
  }
  if (files && files.length === 1) {
    errors.files = 'both_required';
  }

  return errors;
};

const Form = (props) => {
  const {
    handleSubmit,
    errors,
    submitErrors,
    submitFailed,
    setFront,
    setBack,
    isMobile,
    removeFront,
    removeBack,
    initialValues,
    submitting,
    isUploadingToCasper,
    showSubmitButton = true,
    isFrontLoading,
    isBackLoading,
  } = props;

  const Container = isMobile ? DropzonesMobileContainer : DropzonesDesktopContainer;

  return (
    <form onSubmit={handleSubmit}>
      {submitFailed && (
        <FormErrors
          errors={{
            ...errors,
            ...submitErrors,
          }}
          formName={FORM_NAME}
        />
      )}
      <Container
        style={{
          justifyContent: 'left',
        }}
      >
        <FilePreviewProvider>
          <Field
            name="files[0]"
            data-test-id="insurance-card-front"
            component={DropzoneField}
            label="Front of card"
            handler={setFront}
            removeItem={removeFront}
            isLoading={isFrontLoading}
          />
          <Field
            name="files[1]"
            data-test-id="insurance-card-back"
            component={DropzoneField}
            label="Back of card"
            handler={setBack}
            removeItem={removeBack}
            isLoading={isBackLoading}
          />
        </FilePreviewProvider>
      </Container>
      <LowerContainer>
        {initialValues && initialValues?.updated_at && (
          <DateContainer variant="b2">
            {`Last updated: ${formatDate(initialValues.updated_at, 'll')}`}
          </DateContainer>
        )}

        {showSubmitButton && (
          <StyledButton
            type="submit"
            designType="primary"
            size="medium"
            disabled={submitting || isUploadingToCasper}
          >
            {' '}
            {submitting ? 'Sending...' : 'Send'}
          </StyledButton>
        )}
      </LowerContainer>
    </form>
  );
};

Form.propTypes = {
  handleSubmit: PropTypes.func.isRequired,
  submitFailed: PropTypes.bool.isRequired,
  submitErrors: PropTypes.shape({}),
  errors: PropTypes.shape({}),
  setFront: PropTypes.func.isRequired,
  removeFront: PropTypes.func.isRequired,
  setBack: PropTypes.func.isRequired,
  removeBack: PropTypes.func.isRequired,
  isMobile: PropTypes.bool.isRequired,
  submitting: PropTypes.bool.isRequired,
  initialValues: PropTypes.shape({}),
  isUploadingToCasper: PropTypes.bool,
  isFrontLoading: PropTypes.bool,
  isBackLoading: PropTypes.bool,
};

const mapDispatchToProps = (dispatch) => ({
  setFront: (file) => {
    const validFile = filterOutInvalidFile(file);
    if (validFile.length > 0) {
      dispatch(change(FORM_NAME, 'files[0]', validFile));
    }
  },
  setBack: (file) => {
    const validFile = filterOutInvalidFile(file);
    if (validFile.length > 0) {
      dispatch(change(FORM_NAME, 'files[1]', validFile));
    }
  },
  removeFront: (e) => {
    e.preventDefault();
    e.stopPropagation();

    dispatch(clearFields(FORM_NAME, true, true, 'files[0]'));
  },
  removeBack: (e) => {
    e.preventDefault();
    e.stopPropagation();

    dispatch(clearFields(FORM_NAME, true, true, 'files[1]'));
  },
});

export default withWindowSize(
  connect(
    null,
    mapDispatchToProps
  )(
    reduxFormWithErrors({
      formName: FORM_NAME,
      form: Form,
      validate,
    })
  )
);
