import { configureStore as rtkConfigureStore } from '@reduxjs/toolkit';
import {
  TypedUseSelectorHook,
  useDispatch as useAppDispatch,
  useSelector as useAppSelector,
} from 'react-redux';
import coreApi from 'redux/api/coreApi';
import ehrIntegrationApi from 'redux/api/ehrIntegrationApi';
import iOSNativeBridgeMiddleware from 'redux/middlewares/iOSNativeBridge';
import {
  PatientRootState,
  appReducer as patientReducer,
} from 'redux/modules/patient/reducers';
import patientSagas from 'redux/modules/patient/sagas';
import {
  FLUSH,
  PAUSE,
  PERSIST,
  PURGE,
  REGISTER,
  REHYDRATE,
  persistReducer,
  persistStore,
} from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import createReduxPromiseListener from 'redux-promise-listener';
import createSagaMiddleware from 'redux-saga';

const sagaMiddleware = createSagaMiddleware();
const reduxPromiseListener = createReduxPromiseListener();

const appliedMiddlwares = [
  reduxPromiseListener.middleware,
  sagaMiddleware,
  iOSNativeBridgeMiddleware,
];

const persistConfig = {
  key: 'root',
  version: 1,
  storage,
  whitelist: ['scheduling', 'currentPatient'],
};

const store = rtkConfigureStore({
  reducer: persistReducer(persistConfig, patientReducer),
  preloadedState: {},
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      immutableCheck: false,
      serializableCheck: {
        ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
      },
    }).concat(
      ...appliedMiddlwares,
      ehrIntegrationApi.middleware,
      coreApi.middleware
    ),
});

export const persistor = persistStore(store);

sagaMiddleware.run(patientSagas);

export type AppDispatch = typeof store.dispatch;

const { dispatch } = store;

const useDispatch = () => useAppDispatch<AppDispatch>();

const useSelector: TypedUseSelectorHook<PatientRootState> = useAppSelector;

export { dispatch, reduxPromiseListener, store, useDispatch, useSelector };
