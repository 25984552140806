import logger from '@klara/logger';
import { getAppClient } from 'boot/application';
import Cookies from 'js-cookie';
import jwtDecode from 'jwt-decode';
import isEmpty from 'lodash/isEmpty';
import omit from 'lodash/omit';
import { setLastActivity } from 'services/LocalStorageService';

import parseQueryString from 'util/parseQueryString';
import generateUuid from 'util/uuid';


import { klaraLocalStorage, klaraSessionStorage } from './KlaraStorage';
import { removeOneSignalExternalUserId } from './OneSignalService';

export const AUTH_TOKEN_ALIAS = 'X-AUTH-TOKEN';

let globalToken = null;

export const sessionId = generateUuid();

const getGlobalToken = () => globalToken;

const setGlobalToken = (token) => {
  globalToken = token;
};

const getTokenFromLocalStorage = (type) => {
  let token;

  try {
    token = klaraLocalStorage.getItem(type);
  } catch (e) {
    logger.error('Error while reading from localStorage');
  }

  return token;
};

const getTokenFromCookies = Cookies.get;

const setTokenInLocalStorage = (token, type) => {
  try {
    klaraLocalStorage.setItem(type, token);
  } catch (e) {
    logger.error('Error while writing to localStorage');
  }
};

const setTokenInCookies = (token, type) => Cookies.set(type, token);

const service = {
  destroySession: (client = 'doctor') => {
    if (client === 'doctor') removeOneSignalExternalUserId();
    service.resetToken();
    service.clearSignupPhoneNumber();
  },

  getToken: () =>
    getGlobalToken() ||
    getTokenFromLocalStorage(AUTH_TOKEN_ALIAS) ||
    getTokenFromCookies(AUTH_TOKEN_ALIAS),

  getRefreshToken: () => getTokenFromLocalStorage('refreshToken'),

  get2FAToken: (account) => getTokenFromLocalStorage(account),

  getConversationAccessToken: (conversationId) => {
    const tokensFromLocalStorage = getTokenFromLocalStorage('conversationAccessToken');
    const tokensFromCookies = getTokenFromCookies('conversationAccessToken');

    if (tokensFromLocalStorage) {
      return JSON.parse(tokensFromLocalStorage)[conversationId];
    }

    if (tokensFromCookies) {
      return JSON.parse(tokensFromCookies)[conversationId];
    }

    return undefined;
  },

  getConversationAccessTokens: () => {
    const tokensFromLocalStorage = getTokenFromLocalStorage('conversationAccessToken');
    const tokensFromCookies = getTokenFromCookies('conversationAccessToken');
    const parsedTokensFromLocalStorage =
      tokensFromLocalStorage && JSON.parse(tokensFromLocalStorage);
    const parsedTokensFromCookies = tokensFromCookies && JSON.parse(tokensFromCookies);

    if (!isEmpty(parsedTokensFromLocalStorage)) {
      return parsedTokensFromLocalStorage;
    }

    if (!isEmpty(parsedTokensFromCookies)) {
      return parsedTokensFromCookies;
    }
    return {};
  },

  setToken: (authToken) => {
    const client = getAppClient();
    if (client !== 'doctor') {
      setTokenInLocalStorage(authToken, AUTH_TOKEN_ALIAS);
      setTokenInCookies(authToken, AUTH_TOKEN_ALIAS);
    }
    setGlobalToken(authToken);
  },

  setPatientRefreshToken: (refreshToken) => {
    setTokenInLocalStorage(refreshToken, 'refreshToken');
  },

  setPatientAccessToken: (accessToken) => {
    setGlobalToken(accessToken);
  },

  set2FAToken: (account, token) => {
    setTokenInLocalStorage(token, account);
  },

  setConversationAccessToken: (conversationAccessToken, conversationId) => {
    const newState = { [conversationId]: conversationAccessToken };
    const currentState = service.getConversationAccessTokens();
    const conversationTokenObj = { ...currentState, ...newState };

    setTokenInLocalStorage(JSON.stringify(conversationTokenObj), 'conversationAccessToken');
    setTokenInCookies(JSON.stringify(conversationTokenObj), 'conversationAccessToken');
  },

  resetToken: () => {
    klaraLocalStorage.removeItem(AUTH_TOKEN_ALIAS);
    Cookies.remove(AUTH_TOKEN_ALIAS);
    setGlobalToken(null);
  },

  resetConversationAccessToken: (conversationId) => {
    const currentState = service.getConversationAccessTokens();

    if (Object.keys(currentState).length > 0) {
      const newState = omit(currentState, [conversationId]);

      klaraLocalStorage.removeItem('conversationAccessToken');
      Cookies.remove('conversationAccessToken');

      setTokenInLocalStorage(JSON.stringify(newState), 'conversationAccessToken');
      setTokenInCookies(JSON.stringify(newState), 'conversationAccessToken');
    }
  },

  isTokenExpired: (authToken) => {
    if (!authToken) return true;
    try {
      const tokenExpiration = parseInt(jwtDecode(authToken).exp, 10);
      const currentTime = Math.floor(Date.now() / 1000);

      return currentTime > tokenExpiration;
    } catch (error) {
      return true;
    }
  },

  getDecodedAuthToken: (tokenFromStore) => {
    const token = tokenFromStore || service.getToken();

    if (token) return jwtDecode(token);
    return null;
  },

  setLastActivityIfNecessary: () => {
    const { authToken, exchangeToken } = parseQueryString(window.location.href);

    if (authToken || exchangeToken) setLastActivity();
  },

  setSignupPhoneNumber: (phoneNumber) => {
    klaraSessionStorage.setItem('signupTemporaryPhoneNumber', phoneNumber);
  },

  getSignupPhoneNumber: () => klaraSessionStorage.getItem('signupTemporaryPhoneNumber'),

  clearSignupPhoneNumber: () => klaraSessionStorage.removeItem('signupTemporaryPhoneNumber'),

  setWidgetId: (widgetId) => {
    klaraSessionStorage.setItem('klara:widgetId', widgetId);
  },
  getWidgetId: () => klaraSessionStorage.getItem('klara:widgetId'),
  clearWidgetId: () => klaraSessionStorage.removeItem('klara:widgetId'),

  // Team id to redirect from public scheduling to signup flow on clicking "Message us" button
  setTeamId: (teamId) => {
    klaraSessionStorage.setItem('klara:teamId', teamId);
  },
  getTeamId: () => klaraSessionStorage.getItem('klara:teamId'),
  clearTeamId: () => klaraSessionStorage.removeItem('klara:teamId'),

  setLastLoggedInTeam: (teamId) => {
    klaraLocalStorage.setItem('klara:lastUsedteamId', teamId);
  },

  getLastLoggedInTeam: () => klaraLocalStorage.getItem('klara:lastUsedteamId'),

  // Patient information for public scheduling
  setPatientName: (name) => {
    klaraSessionStorage.setItem('patientName', name);
  },
  getPatientName: () => klaraSessionStorage.getItem('patientName'),
  clearPatientName: () => klaraSessionStorage.removeItem('patientName'),

  setPatientLastname: (lastname) => {
    klaraSessionStorage.setItem('patientLastname', lastname);
  },
  getPatientLastname: () => klaraSessionStorage.getItem('patientLastname'),
  clearPatientLastname: () => klaraSessionStorage.removeItem('patientLastname'),

  setPatientDOB: (DOBstring) => {
    klaraSessionStorage.setItem('patientDOB', DOBstring);
  },
  getPatientDOB: () => klaraSessionStorage.getItem('patientDOB'),
  clearPatientDOB: () => klaraSessionStorage.removeItem('patientDOB'),
};

export default service;
