import DocumentEndpoint from 'persistence/patient/DocumentEndpoint';
import { fetch } from 'persistence/reduxFetch';
import { FORM_NAME } from 'redux/forms/patient/documentUploadForm';
import { defineTypes } from 'redux/helpers';
import { actions as AlertActions } from 'redux/modules/common/alerts';
import {
  actions as casperActions,
  selectors as casperSelectors,
} from 'redux/modules/common/casper';
import { getFormValues } from 'redux-form';
import { put, call, select, delay, takeLatest } from 'redux-saga/effects';

export const createTypes = defineTypes('PATIENT/DOCUMENT/POST');

export const initialState = {
  isLoading: false,
  documents: [],
};

export const actions = {
  create(params) {
    return {
      type: createTypes.REQUEST,
      ...params,
    };
  },
};

export const reducer = (state = initialState, { type }) => {
  switch (type) {
    default:
      return state;
  }
};

export const selectors = {
  documentByCategoryId: ({ documents: { documents } }, documentCategoryId) =>
    documents.filter((d) => d.document_category.id === documentCategoryId),
  isLoading: ({ documents: { isLoading } }) => isLoading,
  attachedFilesPendingUpload: (state) => getFormValues(FORM_NAME)(state)?.files,
};

export function* createSaga({ documentCategoryId, files, resolve, reject }) {
  try {
    if (files) {
      for (let i = 0; i < files.length; i += 1) {
        yield put(casperActions.uploadPatientFileToCasper(files[i], FORM_NAME));
      }
    }
    // wait until all files are uploaded to casper
    let isFileUploadingToCasper;

    do {
      isFileUploadingToCasper = yield select(casperSelectors.isUploading);
      yield delay(500);
    } while (isFileUploadingToCasper);

    const uploadedFiles = yield select(casperSelectors.casperFile);

    if (!documentCategoryId || uploadedFiles.length === 0) {
      resolve([]);
      return uploadedFiles;
    }
    const { id: documentId } = yield call(
      fetch,
      DocumentEndpoint.create(documentCategoryId, uploadedFiles)
    );
    yield put(casperActions.resetCasperFile());

    resolve(documentId);

    return documentId;
  } catch (error) {
    reject(error);
    yield put(casperActions.resetCasperFile());
    yield put({
      type: createTypes.FAILURE,
      error,
    });
    yield put(AlertActions.pushDefaultErrorAndNotify(error));

    return -1;
  }
}

export function* rootSaga() {
  yield takeLatest(createTypes.REQUEST, createSaga);
}
