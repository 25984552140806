
import { combineReducers } from 'redux';
import coreApi from 'redux/api/coreApi';
import ehrIntegrationApi from 'redux/api/ehrIntegrationApi';
import commonReducers from 'redux/modules/common/reducers';
import { destroySessionTypes } from 'redux/modules/common/session';

import { reducer as currentPatientReducer } from './currentPatient';
import { schedulingReducer } from './ehr/scheduling';
import { reducer as schedulingQuestionnaireReducer } from './ehr/scheduling/schedulingQuestionnaire';
import { reducer as exchangeKeycloakReducer } from './exchangeKeycloakToken';
import { reducer as PatientPhoneVerificationReducer } from './phoneVerification';

export const appReducer = combineReducers({
  ...commonReducers,
  [ehrIntegrationApi.reducerPath]: ehrIntegrationApi.reducer,
  [coreApi.reducerPath]: coreApi.reducer,
  scheduling: schedulingReducer,
  patientPhoneVerification: PatientPhoneVerificationReducer,
  currentPatient: currentPatientReducer,
  // exchangeToken: exchangeTokenReducer,
  // documents: documentsReducer,
  // documentRequest: documentRequestReducer,
  schedulingQuestionnaire: schedulingQuestionnaireReducer,
  exchangeKeycloak: exchangeKeycloakReducer,
});

export type PatientRootState = ReturnType<typeof appReducer>;
type Action = { type: string };

export default (state: PatientRootState, action: Action): PatientRootState => {
  if (action.type === destroySessionTypes.SUCCESS) {
    /* eslint-disable no-param-reassign */
    state = undefined;
    /* eslint-enable no-param-reassign */
  }

  return appReducer(state, action);
};
